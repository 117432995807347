import * as React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout/layout"
import TextSection from "@components/textSection"
import PartnersList from "@components/partnersList"
import GlowBackground from "@components/elements/glowBackground"

const Partners = ({ data }: any) => {
  const { meta_title, meta_description, meta_image, body } =
    data.prismicPartners.data

  return (
    <Layout
      title={meta_title}
      description={meta_description}
      metaImage={meta_image?.url}
    >
      <GlowBackground>
        <TextSection
          tagline="Partners"
          title="An unprecedented level of access to today’s best <b>quantum hardware</b>"
          text="Our partnerships provide Phasecraft with unique opportunities to scale development of our IP,  inform the development of next-generation quantum hardware, and accelerate commercialisation of high-value breakthroughs."
        />
        {body.map(item => (
          <PartnersList
            key={item.id}
            title={item.primary?.title}
            items={item.items.reduce((result, { partner }) => {
              partner?.document &&
                result.push({
                  id: partner.document.id,
                  title: partner.document.data.name,
                  text: partner.document.data.description.text,
                })

              return result
            }, [])}
          />
        ))}
      </GlowBackground>
    </Layout>
  )
}

export default Partners

export const pageQuery = graphql`
  query {
    prismicPartners {
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        body {
          ... on PrismicPartnersDataBodyListOfPartners {
            id
            primary {
              title
            }
            items {
              partner {
                document {
                  ... on PrismicPartner {
                    id
                    data {
                      name
                      description {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
